import { Area, AreaExtensions, AreaPlugin } from "rete-area-plugin";
import { AreaExtra, Node, Schemes, globalArea, globalEditor } from "../components/editor";
import { ClassicPreset, ConnectionBase, NodeEditor } from "rete";
import { DatasetImporter } from "../components/ReteNodes/DatasetImporter";
import { ProblemContextType } from "../contexts/ProblemContext";
import { PackageImporter } from "../components/ReteNodes/PackageImporter";
import { DataframeLoader } from "../components/ReteNodes/DataframeLoader";
import { Connection } from "../components/editor";
import { axiosInstance, delayMs, getControlDataForNode, getRelevantEventData } from "./nodeHelpers";
import { DataframeInspector } from "../components/ReteNodes/DataframeInspector";
import { CodeViewer } from "../components/ReteNodes/CodeViewer";
import { ConsoleViewer } from "../components/ReteNodes/ConsoleViewer";
import { TestTrainSplit } from "../components/ReteNodes/TestTrainSplit";
import { TrainModel } from "../components/ReteNodes/TrainModel";
import { LogisticRegressionModel } from "../components/ReteNodes/Models/LogisticRegressionModel";
import { ScoreModelBasic } from "../components/ReteNodes/ScoreModelBasic";
import { LinearRegressionModel } from "../components/ReteNodes/Models/LinearRegressionModel";
import { Visualizer } from "../components/ReteNodes/Visualizer";
import { Dispatch, SetStateAction } from "react";
import { PredictModel } from "../components/ReteNodes/PredictModel";

type EditorStateConnection = {
    source: {
        id: string;
        blockType: string;
    };
    target: {
        id: string;
        blockType: string;
    };
};
export type EditorStateNode = {
    id: string;
    name: string;
    controlData: any;
};

export type EditorState = {
    nodes: EditorStateNode[];
    connections: EditorStateConnection[];
    paths: PathSummary[];
};

type PathSummary = {
    pathNumber: number;
    startNode: string;
    endNode: string;
};

export const getEditorState = () => {
    const editorState: EditorState = { nodes: [], connections: [], paths: [] };
    const nodes = globalEditor.getNodes();
    const connections = globalEditor.getConnections();
    for (const node of nodes) {
        const blockType = node.getType();
        const blockDisplayName = node.getDisplayName();
        const controlData = getControlDataForNode(blockType, { data: node });

        editorState.nodes.push({
            id: node.id,
            name: blockDisplayName,
            controlData,
        });
    }
    for (const connection of connections) {
        const source_node = globalEditor.getNode(connection.source);
        const target_node = globalEditor.getNode(connection.target);

        editorState.connections.push({
            source: {
                id: source_node.id,
                blockType: source_node.getDisplayName(),
            },
            target: {
                id: target_node.id,
                blockType: target_node.getDisplayName(),
            },
        });
    }

    const neverStartingNode = ["Linear Regression Model", "Logistic Regression Model"];
    const starterNodes = editorState.nodes.filter(
        (node) =>
            !connections.some((conn) => conn.target === node.id) &&
            connections.some((conn) => conn.source === node.id) &&
            !neverStartingNode.includes(node.name)
    );

    if (starterNodes.length === 0) {
        console.log("No starter nodes found");
        return;
    }

    const leafNodes = editorState.nodes.filter(
        (node) =>
            !connections.some((conn) => conn.source === node.id) &&
            connections.some((conn) => conn.target === node.id)
    );

    if (leafNodes.length === 0) {
        console.log("No leaf nodes found");
        return;
    }

    const pathSummaries: PathSummary[] = leafNodes.map((node, index) => {
        return {
            pathNumber: index + 1,
            startNode: starterNodes.map((node) => node.name).join(" + "),
            endNode: node.name,
        };
    });
    editorState.paths.push(...pathSummaries);
    return editorState;
};

interface serializeableProps {
    [key: string]: any;
}

const filterOutMethods = (obj: any, seen: WeakSet<object>) => {
    if (typeof obj !== "object" || obj === null) {
        return obj;
    }
    if (seen.has(obj)) {
        return;
    }
    seen.add(obj);
    const keys_to_remove = ["plotType"];
    const newObj: serializeableProps = Array.isArray(obj) ? [] : {};
    Object.keys(obj).forEach((key) => {
        const value = obj[key];
        // if (keys_to_remove.includes(key)) {
        //     return;
        // }
        if (Array.isArray(value)) {
            newObj[key] = value.slice();
        } else if (value && typeof value === "object") {
            if (key === "uploadedDataset") {
                newObj[key] = {
                    content: "",
                    fileName: value.fileName,
                };
                return;
            }
            newObj[key] = filterOutMethods(value, seen);
        } else if (typeof value !== "function" && !(value instanceof HTMLElement)) {
            newObj[key] = value;
        }
    });
    return newObj;
};

function getCustomNodeData(node: any) {
    const seen = new WeakSet();
    let filteredNode = filterOutMethods(node, seen);
    if (node.getType() === "DatasetImporter") {
        filteredNode = {
            ...filteredNode,
            height: 295,
        };
    }
    return filteredNode;
}

export function exportGraph(editor: NodeEditor<Schemes>, area: AreaPlugin<Schemes, AreaExtra>) {
    const data: any = { nodes: [], connections: [] };
    const nodes = editor.getNodes();
    const connections = editor.getConnections();

    for (const node of nodes) {
        const customData = getCustomNodeData(node);
        const position = area.nodeViews.get(node.id)?.position;

        data.nodes.push({
            ...customData,
            __type: node.getType(),
            position,
            selected: false,
        });
    }

    for (const connection of connections) {
        data.connections.push({
            source: connection.source,
            sourceOutput: connection.sourceOutput,
            target: connection.target,
            targetInput: connection.targetInput,
        });
    }
    const serializedData = JSON.stringify(data);
    localStorage.setItem("graph", serializedData);
    return serializedData;
}

interface NodeConstructorParams {
    socket: ClassicPreset.Socket;
    updateAsset: (
        type: "socket" | "node" | "connection" | "control" | "contextmenu",
        asset:
            | ClassicPreset.Control
            | ClassicPreset.Node
            | ClassicPreset.Input<ClassicPreset.Socket>
    ) => void;
    process: () => void;
    context: ProblemContextType;
    setIsVisualizerOpen: Dispatch<SetStateAction<boolean>>;
}

export function createNode(type: string, params: NodeConstructorParams) {
    const { socket, updateAsset, process, context, setIsVisualizerOpen } = params;
    switch (type) {
        case "DatasetImporter":
            return new DatasetImporter(socket, updateAsset, process, context);
        case "PackageImporter":
            return new PackageImporter(socket, process, updateAsset, context);
        case "DataframeLoader":
            return new DataframeLoader(socket, updateAsset, process, context);
        case "DataframeInspector":
            return new DataframeInspector(socket, updateAsset, process, context);
        case "CodeViewer":
            return new CodeViewer(socket, updateAsset);
        case "ConsoleViewer":
            return new ConsoleViewer(socket, updateAsset);
        case "PredictModel":
            return new PredictModel(socket);
        case "TestTrainSplit":
            return new TestTrainSplit(socket, updateAsset, process, context);
        case "TrainModel":
            return new TrainModel(socket);
        case "LogisticRegressionModel":
            return new LogisticRegressionModel(socket, updateAsset, process);
        case "LinearRegressionModel":
            return new LinearRegressionModel(socket, updateAsset, process);
        case "TrainModel":
            return new TrainModel(socket);
        case "ScoreModelBasic":
            return new ScoreModelBasic(socket);
        case "Visualizer":
            return new Visualizer(socket, updateAsset, setIsVisualizerOpen, process);
        default:
            throw new Error(`Unknown node type: ${type}`);
    }
}

export async function importGraph(
    editor: NodeEditor<Schemes>,
    area: AreaPlugin<Schemes, AreaExtra>,
    socket: ClassicPreset.Socket,
    updateAsset: (
        type: "socket" | "node" | "connection" | "control" | "contextmenu",
        asset:
            | ClassicPreset.Control
            | ClassicPreset.Node
            | ClassicPreset.Input<ClassicPreset.Socket>
    ) => void,
    process: () => void,
    context: ProblemContextType,
    setIsVisualizerOpen: Dispatch<SetStateAction<boolean>>,
    problem_id: number
) {
    const data = await loadGraph(problem_id);
    const parsedData = JSON.parse(data);
    const nodes = parsedData?.nodes || [];
    const connections: any[] = parsedData?.connections || [];

    for (const node of nodes) {
        const {
            position,
            __type,
            controls,
            context: contextData,
            ImportManager,
            uploadedDataset,
            inputs,
            outputs,
            ...nodeData
        } = node;
        const newNode = createNode(__type, {
            socket,
            updateAsset,
            process,
            context,
            setIsVisualizerOpen,
        });
        const controlData = Object.values(controls)[0];

        Object.assign(newNode, nodeData);
        if (Object.values(newNode.controls).length > 0) {
            const needsSetup = "setupControls" in Object.values(newNode.controls)[0];

            if (needsSetup) {
                Object.values(newNode.controls)[0].setupControls(controlData);
            }
        }

        await editor.addNode(newNode);
        await area.translate(newNode.id, position);
    }

    for (const connection of connections) {
        const { source, sourceOutput, target, targetInput } = connection;
        const sourceNode = editor.getNode(source);
        const targetNode = editor.getNode(target);
        const newConnection = new Connection(
            sourceNode,
            sourceOutput as never,
            targetNode,
            targetInput as never
        );
        await editor.addConnection(newConnection);
    }
}

export async function saveGraph(problem_id: number) {
    const graphData = exportGraph(globalEditor, globalArea);
    try {
        const response = await axiosInstance.post(
            "/api/editor/saveGraph",
            {
                graphData,
                problem_id,
            },
            { withCredentials: true }
        );
    } catch (error: any) {
        console.log("error", error.response.data);
    }
}

export const loadGraph = async (problem_id: number) => {
    try {
        const response = await axiosInstance.get("/api/editor/getGraph", {
            params: {
                problem_id,
            },
            withCredentials: true,
        });
        const graphData: string = response.data.graphData;
        return graphData;
    } catch (error: any) {
        console.log("error", error.response.data);
        return "{}";
    }
};
