import { ProblemContextType } from "../../contexts/ProblemContext";
import { ClassicPreset } from "rete";
import { UploadedDataset } from "../ReteInterfaces/SharedInterfaces";
import { borderTopRightRadius } from "html2canvas/dist/types/css/property-descriptors/border-radius";

export class DatasetImporterTextControls extends ClassicPreset.Control {
    uploadedDataset: UploadedDataset = {
        content: "",
        fileName: "",
    };
    constructor(
        public value: string,
        public useUploadedDataset: boolean,
        public onChange: (value: string) => void,
        public isValidCsvUrl: (url: string) => boolean,
        public setDatasetColumns: (datasetColumns: string[]) => void,
        public uploadDataset: (dataset: string, file: string | null) => void,
        public setDatasetSource: (useImported: boolean) => void,
        public context: ProblemContextType,
        public signalParseCsvComplete: () => void,
        public adjustHeight: (height: number) => void
    ) {
        super();
    }

    setValue(value: string) {
        this.value = value;
    }

    setUseUploadedDataset(useUploadedDataset: boolean) {
        this.useUploadedDataset = useUploadedDataset;
    }

    setUploadedDataset(content: string, fileName: string | null) {
        this.uploadedDataset = {
            content: content,
            fileName: fileName || "",
        };
    }

    setupControls(controlData: any) {
        this.value = controlData.value;
        this.useUploadedDataset = controlData.useUploadedDataset;
        this.uploadDataset(
            controlData.uploadedDataset?.content || "",
            controlData.uploadedDataset?.fileName || ""
        );
    }
}
