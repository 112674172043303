import React, { useEffect, useState } from "react";

interface SaveIndicatorProps {
    status: string;
    setStatus: React.Dispatch<React.SetStateAction<string>>;
    max_interval_seconds: number;
    resetTrigger: boolean;
    saveIndicatorMessageRef: React.RefObject<HTMLDivElement>;
}

const SaveIndicator = ({
    status,
    setStatus,
    max_interval_seconds,
    resetTrigger,
    saveIndicatorMessageRef,
}: SaveIndicatorProps) => {
    const [countDown, setCountDown] = useState(0);
    const [dots, setDots] = useState(0);

    useEffect(() => {
        if (status) {
            setCountDown(max_interval_seconds);
        }
    }, [resetTrigger]);

    useEffect(() => {
        if (status === "pending") {
            const timer = setInterval(() => {
                setCountDown((prev) => prev - 0.25);
            }, 250);

            if (countDown <= 0) {
                clearInterval(timer);
            }

            return () => clearInterval(timer);
        }
    }, [countDown]);

    // Saved status timeout
    useEffect(() => {
        let timeoutId: NodeJS.Timeout | null = null;
        if (status === "saved") {
            timeoutId = setTimeout(() => {
                setStatus("");
            }, 2000);
        }

        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [status]);

    // Submitting status dots animation
    useEffect(() => {
        let interval: NodeJS.Timeout | null = null;
        if (status === "submitting") {
            interval = setInterval(() => {
                setDots((prevDots) => (prevDots + 1) % 5);
            }, 750);
        } else {
            setDots(0);
        }

        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [status]);

    return (
        <div ref={saveIndicatorMessageRef} className="saveIndicatorContainer">
            <span className="saveIndicatorText">
                {status === "pending" && `Pending auto-save in ${countDown.toFixed(0)}...`}
                {status === "saved" && "Successfully saved!"}
                {status === "submitting" && `Submitting${" .".repeat(dots)}`}
            </span>
        </div>
    );
};

export default SaveIndicator;
