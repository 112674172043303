import React, { createContext, useContext, useEffect, useState, ReactNode } from "react";
import { useParams } from "react-router-dom";
import { axiosInstance } from "../helpers/nodeHelpers";
import { EditorState } from "../helpers/importExportFunctions";

export type TaskType = {
    id: number;
    task_number: number;
    description: string;
    title: string;
    action: string;
    is_completed: boolean;
};

type ProblemType = {
    problem_number: number;
    title: string;
};

export type ProblemSubmission = {
    is_first_submission: boolean;
    submission_number: number;
    date_started: Date;
    date_submission_started?: Date;
    date_completed: Date;
    confidence: number;
    full_code: string;
    console_output: string;
    image_url: string;
    editor_state: EditorState;
};

type SubmissionImage = {
    id: number;
    image_path: string;
    image_title: string;
    problem_submission_id: number;
};

interface SubmissionDetails {
    problem_submission: ProblemSubmission;
    tasks: TaskType[];
    status: string;
    problem: ProblemType;
    problem_submission_numbers: number[];
    submission_images: SubmissionImage[];
}

interface SubmissionContextType {
    submissionDetails: SubmissionDetails | null;
    setSubmissionDetails: React.Dispatch<React.SetStateAction<SubmissionDetails | null>>;
    isSubmitted: boolean;
    isLoading: boolean;
}

const SubmissionContext = createContext<SubmissionContextType | undefined>(undefined);

export const useSubmission = (): SubmissionContextType => {
    const context = useContext(SubmissionContext);
    if (context === undefined) {
        throw new Error("useSubmission must be used within a ProblemSubmissionProvider");
    }
    return context;
};

interface ProblemSubmissionProviderProps {
    children: ReactNode;
}

const ProblemSubmissionProvider: React.FC<ProblemSubmissionProviderProps> = ({ children }) => {
    const { problem_id } = useParams<{ problem_id: string }>();
    const [isSubmitted, setIsSubmitted] = useState<boolean | null>(null);
    const [submissionDetails, setSubmissionDetails] = useState<SubmissionDetails | null>(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const checkSubmissionStatus = async () => {
            setIsLoading(true);
            try {
                const response = await axiosInstance.get(
                    `/api/problems/get-problem/${problem_id}/submission-status`
                );
                setIsSubmitted(response.data.isSubmitted);
                setSubmissionDetails({ ...response.data.userProblemData });
            } catch (error) {
                console.error("Error fetching submission status:", error);
            } finally {
                setIsLoading(false);
            }
        };
        checkSubmissionStatus();
    }, [problem_id]);

    const contextValue = {
        submissionDetails,
        setSubmissionDetails,
        isSubmitted: !!isSubmitted,
        isLoading,
    };

    return <SubmissionContext.Provider value={contextValue}>{children}</SubmissionContext.Provider>;
};

export default ProblemSubmissionProvider;
