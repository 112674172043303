import { createRoot } from "react-dom/client";
import { NodeEditor, BaseSchemes, GetSchemes, ClassicPreset } from "rete";
import { AreaPlugin, AreaExtensions, Area } from "rete-area-plugin";
import { ConnectionPlugin, Presets as ConnectionPresets } from "rete-connection-plugin";
import { ReactPlugin, Presets, ReactArea2D } from "rete-react-plugin";
import { DataflowEngine } from "rete-engine";
import {
    ContextMenuExtra,
    ContextMenuPlugin,
    Presets as ContextMenuPresets,
} from "rete-context-menu-plugin";
import { HistoryExtensions, HistoryPlugin, Presets as HistoryPresets } from "rete-history-plugin";
import {
    AutoArrangePlugin,
    Presets as ArrangePresets,
    ArrangeAppliers,
} from "rete-auto-arrange-plugin";
import { structures } from "rete-structures";
import CustomDropdownList from "./ReteComponents/CustomDropdownList";
import { DropdownListControl } from "./ReteControls/DropdownListControl";
import {
    Dispatch,
    JSXElementConstructor,
    MutableRefObject,
    SetStateAction,
    useContext,
} from "react";
import { PackageImporter } from "./ReteNodes/PackageImporter";
import { CodeViewerControl } from "./ReteControls/CodeViewerControls";
import CodeDisplayElement from "./ReteComponents/CodeViewerComponent";
import { CodeViewer } from "./ReteNodes/CodeViewer";
import { ConsoleViewer } from "./ReteNodes/ConsoleViewer";
import { DatasetImporter } from "./ReteNodes/DatasetImporter";
import { DatasetImporterComponent } from "./ReteComponents/DatasetImporter/DatasetImporterComponent";
import { DatasetImporterTextControls } from "./ReteControls/DatasetImporterTextControl";

import styled from "styled-components";
import customItem from "./ReteComponents/CustomItem";
import ConsoleViewerComponent from "./ReteComponents/ConsoleViewerComponent";
import { ConsoleViewerControl } from "./ReteControls/ConsoleViewerControls";
import { DataframeInspector } from "./ReteNodes/DataframeInspector";
import { DataframeInspectorControl } from "./ReteControls/DataframeInspectorControls";
import DataframeInspectorComponent from "./ReteComponents/DataframeInspectorComponent";
import { exportGraph, importGraph, saveGraph } from "../helpers/importExportFunctions";
import { TestTrainSplit } from "./ReteNodes/TestTrainSplit";
import { TestTrainSplitControl } from "./ReteControls/TestTrainSplitControls";
import TestTrainSplitComponent from "./ReteComponents/TestTrainSplitComponent";
import { DataframeLoader } from "./ReteNodes/DataframeLoader";
import { DataframeLoaderControl } from "./ReteControls/DataframeLoaderControls";
import DataframeLoaderComponent from "./ReteComponents/DataframeLoaderComponent";
import {
    delayFunction,
    delayMs,
    getConnectionNodeData,
    getDetailsFromAction,
    getRelevantEventData,
    saveUserLog,
} from "../helpers/nodeHelpers";
import { ProblemContextType } from "../contexts/ProblemContext";
import { LinearRegressionModel } from "./ReteNodes/Models/LinearRegressionModel";
import { LinearRegressionSettings } from "./ReteControls/Models/LinearRegressionSettings";
import LinearRegressionSettingsComponent from "./ReteComponents/Models/LinearRegressionSettingsComponent";
import { TrainModel } from "./ReteNodes/TrainModel";
import { PredictModel } from "./ReteNodes/PredictModel";
import { ScoreModelBasic } from "./ReteNodes/ScoreModelBasic";
import { LogisticRegressionSettings } from "./ReteControls/Models/LogisticRegressionSettings";
import LogisticRegressionSettingsComponent from "./ReteComponents/Models/LogisticRegressionSettingsComponent";
import { LogisticRegressionModel } from "./ReteNodes/Models/LogisticRegressionModel";
import { AUTO_SAVE_PENDING_SECONDS, CodePath, EditorNodeData } from "./MainEditor";
import { PreviousNodeData } from "./ReteInterfaces/SharedInterfaces";
import { Visualizer } from "./ReteNodes/Visualizer";
import VisualizerComponent from "./ReteComponents/VisualizerComponent";
import { VisualizerControls } from "./ReteControls/VisualizerControls";
import { selector } from "rete-area-plugin/_types/extensions";
import { SelectorEntity } from "rete-area-plugin/_types/extensions/selectable";
export let debouncedAutoSave: (
    type: string,
    context?: any,
    logOnly?: boolean,
    saveOnly?: boolean
) => void;
const AUTO_SAVE_TRIGGER_DELAY = 250; // ms
export let globalEditor: NodeEditor<Schemes>;
export let globalArea: AreaPlugin<Schemes, AreaExtra>;
export let globalArrange: AutoArrangePlugin<Schemes, AreaExtra>;
export let globalEngine: DataflowEngine<Schemes>;
export let globalSelector: AreaExtensions.Selector<SelectorEntity>;
export let globalSocket: ClassicPreset.Socket;
export let globalUpdateAsset: (
    type: "socket" | "node" | "connection" | "control" | "contextmenu",
    asset: ClassicPreset.Control | ClassicPreset.Node | ClassicPreset.Input<ClassicPreset.Socket>
) => void;
export let globalProcess: () => void;

function makeEditorExportable(
    editor: NodeEditor<Schemes>,
    area: AreaPlugin<Schemes, AreaExtra>,
    arrange: AutoArrangePlugin<Schemes, AreaExtra>,
    engine: DataflowEngine<Schemes>,
    socket: ClassicPreset.Socket,
    updateAsset: (
        type: "socket" | "node" | "connection" | "control" | "contextmenu",
        asset:
            | ClassicPreset.Control
            | ClassicPreset.Node
            | ClassicPreset.Input<ClassicPreset.Socket>
    ) => void,
    process: () => void,
    selector: AreaExtensions.Selector<SelectorEntity>
) {
    globalEditor = editor;
    globalArea = area;
    globalArrange = arrange;
    globalEngine = engine;
    globalSocket = socket;
    globalUpdateAsset = updateAsset;
    globalProcess = process;
    globalSelector = selector;
}

export type Node =
    | PackageImporter
    | CodeViewer
    | ConsoleViewer
    | DatasetImporter
    | DataframeLoader
    | DataframeInspector
    | LinearRegressionModel
    | LogisticRegressionModel
    | PredictModel
    | ScoreModelBasic
    | TrainModel
    | TestTrainSplit
    | Visualizer;

export class Connection<A extends Node> extends ClassicPreset.Connection<A, A> {}

export type Schemes = GetSchemes<Node, Connection<Node>>;
export type AreaExtra = ReactArea2D<Schemes> | ContextMenuExtra;

export async function createEditor(
    container: HTMLElement,
    context: ProblemContextType,
    setCodePreview: Dispatch<SetStateAction<string>>,
    setAutoSaveStatus: Dispatch<SetStateAction<string>>,
    setResetTrigger: Dispatch<SetStateAction<boolean>>,
    setIsVisualizerOpen: Dispatch<SetStateAction<boolean>>,
    setPlotType: Dispatch<SetStateAction<string>>,
    problem_id: number,
    setEditorNodes: Dispatch<SetStateAction<EditorNodeData[]>>,
    isContextMenuSource: MutableRefObject<boolean>,
    setCodePaths: Dispatch<SetStateAction<CodePath[]>>,
    hasProcessStartedRef: MutableRefObject<boolean>
) {
    const socket = new ClassicPreset.Socket("socket");
    const editor = new NodeEditor<Schemes>();
    const area = new AreaPlugin<Schemes, AreaExtra>(container);
    const connection = new ConnectionPlugin<Schemes, AreaExtra>();
    const engine = new DataflowEngine<Schemes>();
    const render = new ReactPlugin<Schemes, AreaExtra>({ createRoot });
    const history = new HistoryPlugin<Schemes>();

    const contextMenu = new ContextMenuPlugin<Schemes>({
        items: ContextMenuPresets.classic.setup([
            [
                "Display",
                [
                    ["Code Viewer", () => new CodeViewer(socket, updateAsset)],
                    ["Console Viewer", () => new ConsoleViewer(socket, updateAsset)],
                    [
                        "Visualizer",
                        () => new Visualizer(socket, updateAsset, setIsVisualizerOpen, process),
                    ],
                ],
            ],
            [
                "Import",
                [
                    [
                        "Dataset Importer",
                        () => new DatasetImporter(socket, updateAsset, process, context),
                    ],
                    [
                        "Library Importer",
                        () => new PackageImporter(socket, process, updateAsset, context),
                    ],
                ],
            ],
            [
                "Dataframe Tools",
                [
                    [
                        "Dataframe Loader",
                        () => new DataframeLoader(socket, updateAsset, process, context),
                    ],
                    [
                        "Dataframe Inspector",
                        () => new DataframeInspector(socket, updateAsset, process, context),
                    ],
                    [
                        "Test Train Split",
                        () => new TestTrainSplit(socket, updateAsset, process, context),
                    ],
                ],
            ],
            [
                "Model",
                [
                    [
                        "Model Selection",
                        [
                            [
                                "Linear Regression",
                                () => new LinearRegressionModel(socket, updateAsset, process),
                            ],
                            [
                                "Logistic Regression",
                                () => new LogisticRegressionModel(socket, updateAsset, process),
                            ],
                        ],
                    ],
                    ["Model Training", [["Train Model", () => new TrainModel(socket)]]],
                    ["Model Prediction", [["Predict Model", () => new PredictModel(socket)]]],
                    ["Model Evaluation", [["Score Model", () => new ScoreModelBasic(socket)]]],
                ],
            ],
        ]),
    });

    const graph = structures(editor);
    const arrange = new AutoArrangePlugin<Schemes, AreaExtra>();
    let isEditorLoaded = false;

    const { markTaskAsCompleted, removeTaskFromCompleted } = context;

    history.addPreset(HistoryPresets.classic.setup());
    const selectorExt = AreaExtensions.selector();
    AreaExtensions.selectableNodes(area, selectorExt, {
        accumulating: AreaExtensions.accumulateOnCtrl(),
    });

    async function process(connectionRemoved: boolean = false, affectedNode?: Node) {
        if (!hasProcessStartedRef.current) {
            hasProcessStartedRef.current = true;
        }
        engine.reset();
        const allNodes = editor.getNodes();
        const isolatedNodes = allNodes.filter(
            (n) =>
                !graph.connections().some((c) => c.source === n.id || c.target === n.id) &&
                (!connectionRemoved || n.id !== affectedNode?.id)
        );

        const leafNodes = graph
            .leaves()
            .nodes()
            .filter((n) => !isolatedNodes.includes(n));
        const targetNodes = leafNodes.filter(
            (n) =>
                n instanceof CodeViewer ||
                n instanceof ConsoleViewer ||
                n instanceof DataframeLoader ||
                n instanceof TestTrainSplit
        );
        const consoleIndex = leafNodes.findIndex((n) => n instanceof ConsoleViewer);
        if (consoleIndex >= 0) {
            const [consoleNode] = leafNodes.splice(consoleIndex, 1);
            leafNodes.push(consoleNode);
        }
        let totalCodePreview = "";
        let codePaths: CodePath[] = [];
        let pathNum = 1;
        try {
            for (const n of leafNodes) {
                const data = await engine.fetch(n.id);
                if (pathNum !== 1) {
                    totalCodePreview += "\n\n";
                }
                const codeOutput: string = Object.values(data)[0].code.join("\n");
                codePaths.push({ pathNum, code: codeOutput });
                totalCodePreview += `#########################\n###### Path ${pathNum} code ######\n#########################\n${codeOutput}`;
                const plotType = Object.values(data)[0].previousNodes.find(
                    (node: PreviousNodeData) => node.label === "Generate Plot"
                )?.plotType;

                setPlotType(plotType);
                pathNum++;
            }
        } catch (error) {
            console.log("Error caught in process function:", error);
        }
        setCodePaths(codePaths);
        setCodePreview(totalCodePreview || "");
    }

    function updateAsset(
        type: "socket" | "node" | "connection" | "control" | "contextmenu",
        asset:
            | ClassicPreset.Control
            | ClassicPreset.Node
            | ClassicPreset.Input<ClassicPreset.Socket>
    ) {
        area.update(type, asset.id);
    }

    type PayloadType =
        | DropdownListControl
        | CodeViewerControl
        | ConsoleViewerControl
        | DatasetImporterTextControls
        | DataframeInspectorControl
        | LinearRegressionSettings
        | DataframeLoaderControl
        | TestTrainSplitControl
        | LogisticRegressionSettings
        | VisualizerControls;

    interface dataType {
        element: HTMLElement;
        filled?: boolean | undefined;
        type: "control";
        payload: PayloadType;
    }

    render.addPreset(Presets.classic.setup());
    render.addPreset(
        Presets.classic.setup({
            customize: {
                control(data: dataType & { payload: PayloadType }) {
                    if (data.payload instanceof DropdownListControl) {
                        return CustomDropdownList as unknown as JSXElementConstructor<{
                            data: ClassicPreset.Control;
                        }>;
                    }
                    if (data.payload instanceof CodeViewerControl) {
                        return CodeDisplayElement as unknown as JSXElementConstructor<{
                            data: ClassicPreset.Control;
                        }>;
                    }
                    if (data.payload instanceof DatasetImporterTextControls) {
                        return DatasetImporterComponent as unknown as JSXElementConstructor<{
                            data: ClassicPreset.Control;
                        }>;
                    }
                    if (data.payload instanceof ConsoleViewerControl) {
                        return ConsoleViewerComponent as unknown as JSXElementConstructor<{
                            data: ClassicPreset.Control;
                        }>;
                    }
                    if (data.payload instanceof DataframeInspectorControl) {
                        return DataframeInspectorComponent as unknown as JSXElementConstructor<{
                            data: ClassicPreset.Control;
                        }>;
                    }
                    if (data.payload instanceof TestTrainSplitControl) {
                        return TestTrainSplitComponent as unknown as JSXElementConstructor<{
                            data: ClassicPreset.Control;
                        }>;
                    }
                    if (data.payload instanceof DataframeLoaderControl) {
                        return DataframeLoaderComponent as unknown as JSXElementConstructor<{
                            data: ClassicPreset.Control;
                        }>;
                    }
                    if (data.payload instanceof LinearRegressionSettings) {
                        return LinearRegressionSettingsComponent as unknown as JSXElementConstructor<{
                            data: ClassicPreset.Control;
                        }>;
                    }
                    if (data.payload instanceof LogisticRegressionSettings) {
                        return LogisticRegressionSettingsComponent as unknown as JSXElementConstructor<{
                            data: ClassicPreset.Control;
                        }>;
                    }
                    if (data.payload instanceof VisualizerControls) {
                        return VisualizerComponent as unknown as JSXElementConstructor<{
                            data: ClassicPreset.Control;
                        }>;
                    }

                    return null;
                },
            },
        })
    );
    const { Menu, Common, Search, Subitems, Item } = Presets.contextMenu;
    const CustomMenu = styled(Menu)`
        width: fit-content;
    `;
    const CustomSubItems = styled(Subitems)`
        width: max-content;
    `;
    const CustomItemType = styled(customItem)`
        background: grey;
    `;
    render.addPreset(
        Presets.contextMenu.setup({
            delay: 200,
            customize: {
                main: () => CustomMenu,
                item: () => CustomItemType as any,
                common: () => Common,
                search: () => Search,
                subitems: () => CustomSubItems,
            },
        })
    );
    render.addPreset(Presets.contextMenu.setup());
    connection.addPreset(ConnectionPresets.classic.setup());
    arrange.addPreset(ArrangePresets.classic.setup());

    editor.use(engine);
    editor.use(area);
    area.use(connection);
    area.use(contextMenu);
    area.use(render);
    area.use(history);
    area.use(arrange);

    AreaExtensions.simpleNodesOrder(area);

    const LOG_EVENT_TYPES: string[] = [
        "nodecreated",
        "noderemoved",
        "connectioncreated",
        "connectionremoved",
    ];

    editor.addPipe(async (context) => {
        const type = context.type;
        if (type === "nodecreated" && isEditorLoaded) {
            if ("task_id" in context.data) {
                const currentAction = context.data.task_id;
                await markTaskAsCompleted(currentAction);
            }
        }
        if (type === "noderemoved") {
            if ("task_id" in context.data) {
                const currentAction = context.data.task_id;
                const typeStillExists = editor
                    .getNodes()
                    .some((n) => (n as Node & { task_id: string }).task_id === currentAction);

                if (!typeStillExists) {
                    await removeTaskFromCompleted(currentAction);
                    if (currentAction === "createLibraryImporter") {
                        // Must also remove importLibraries task since its dependent on library importer
                        await removeTaskFromCompleted("importLibraries");
                    } else if (currentAction === "createDatasetImporter") {
                        // Must also remove importDataset task since its dependent on dataset importer
                        await removeTaskFromCompleted("importDataset");
                    }
                }
            }
        }
        return context;
    });

    const debouncedProcess = delayFunction(
        (removed = false, target = null) => process(removed, target),
        500
    );
    let ignoreNextTwoNodeTranslations = false;
    let ignoreCount = 0;

    // SETUP EDITOR EVENT LISTENERS
    const autoSaveEventTypes = [
        "nodetranslated",
        "noderesized",
        "nodecreated",
        "noderemoved",
        "connectioncreated",
        "connectionremoved",
    ];

    const autoSave = delayFunction((eventType = "Not specified") => {
        setAutoSaveStatus("saved");
        saveGraph(problem_id);
    }, AUTO_SAVE_PENDING_SECONDS * 1000 - AUTO_SAVE_TRIGGER_DELAY);

    debouncedAutoSave = delayFunction((type, context = {}, logOnly = false, saveOnly = false) => {
        if (saveOnly) {
            autoSave();
            return;
        }
        if (!logOnly) {
            setAutoSaveStatus("pending");
            setResetTrigger((prev) => !prev);
            autoSave(type);
        }
        const eventData = getRelevantEventData(type, context);
        const details = getDetailsFromAction(type, eventData) || undefined;

        saveUserLog({ action: type, eventData, details, problem_id });
    }, AUTO_SAVE_TRIGGER_DELAY);

    area.addPipe((context) => {
        const type = context.type;
        if (isEditorLoaded && autoSaveEventTypes.includes(type)) {
            let source = "dragging from Dock Menu";
            let contextCopy = { ...context, source: "" };
            if (type === "nodetranslated" && ignoreNextTwoNodeTranslations) {
                ignoreCount--;
                if (ignoreCount <= 0) {
                    ignoreNextTwoNodeTranslations = false;
                }
                return context;
            } else if (type === "nodecreated") {
                ignoreNextTwoNodeTranslations = true;
                ignoreCount = 2;
                if (isContextMenuSource.current) {
                    isContextMenuSource.current = false;
                    source = "right-click Context Menu";
                }
                contextCopy = { ...context, source };
            }

            debouncedAutoSave(type, contextCopy);
        }

        if (type === "render") {
            const subtype = context.data.type;
            if (subtype === "control") {
                const element = context.data.element;
                element.addEventListener("dblclick", (e) => {
                    e.stopPropagation();
                });
            } else if (subtype === "contextmenu") {
                isContextMenuSource.current = true;
            }
        }
        if (isEditorLoaded && (type === "connectioncreated" || type === "connectionremoved")) {
            const target = editor.getNode(context.data.target);
            if (type === "connectionremoved") {
                debouncedProcess(true, target);
            } else {
                debouncedProcess();
            }
        }

        if ("data" in context) {
            if (LOG_EVENT_TYPES.includes(type)) {
                if (type === "connectionremoved" || type === "connectioncreated") {
                    const connectionData = getConnectionNodeData(context.data);
                }
            }
        }

        return context;
    });

    makeEditorExportable(editor, area, arrange, engine, socket, updateAsset, process, selectorExt);

    await importGraph(
        editor,
        area,
        socket,
        updateAsset,
        process,
        context,
        setIsVisualizerOpen,
        problem_id
    );

    const editorNodeTypes = editor.getNodes().map((node) => {
        return { blockType: node.getType(), id: node.id };
    });
    setEditorNodes(editorNodeTypes);
    setTimeout(() => {
        AreaExtensions.zoomAt(area, editor.getNodes());
        isEditorLoaded = true;
    }, 10);
    setTimeout(async () => {
        const isDatasetImporterPresent = editorNodeTypes.some(
            (type) => type.blockType === "DatasetImporter"
        );

        if (!isDatasetImporterPresent || !hasProcessStartedRef.current) {
            process();
        }
    }, 500);
    return {
        destroy: () => area.destroy(),
    };
}
