import { ClassicPreset } from "rete";

export class TestTrainSplitControl extends ClassicPreset.Control {
    constructor(
        public df_columns: string[],
        public errorMessages: string[],
        public selectedFeatures: string[],
        public targetFeature: string,
        public testSize: number,
        public isLoading: boolean,
        public setSelectedFeatures: (selectedFeatures: string[]) => void,
        public setTargetVariable: (targetFeature: string) => void,
        public setTestSize: (testSize: number) => void
    ) {
        super();
    }

    setDataframeColumns(df_columns: string[]) {
        this.df_columns = [...df_columns];
    }
    setTargetFeature(targetFeature: string) {
        this.targetFeature = targetFeature;
    }
    setIsLoading(isLoading: boolean) {
        this.isLoading = isLoading;
    }
    _setSelectedFeatures(selectedFeatures: string[]) {
        this.selectedFeatures = [...selectedFeatures];
    }
    _setTestSize(testSize: number) {
        this.testSize = testSize;
    }
    setErrorMessages(errorMessages: string[]) {
        this.errorMessages = [...errorMessages];
    }
    setupControls(controlData: any) {
        this.setDataframeColumns(controlData.df_columns);
        this.selectedFeatures = controlData.selectedFeatures;
        this.targetFeature = controlData.targetFeature;
        this.testSize = controlData.testSize;
        this.errorMessages = controlData.errorMessages;
    }
}
