import { Input, Button, Upload, Switch, Flex, ConfigProvider, theme } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { DatasetImporterTextControls } from "../../ReteControls/DatasetImporterTextControl";
import React, { ChangeEvent, useContext, useEffect, useRef, useState } from "react";
import { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";
import DatasetViewerComponent from "./DatasetViewerComponent";
import "../../../styles/DatasetImporter.css";
// import { delayFunction } from "../../../helpers/nodeHelpers";
import { debouncedAutoSave } from "../../editor";
import { axiosInstance } from "../../../helpers/nodeHelpers";
import { HistoryExtensions } from "rete-history-plugin";
import { BACKEND_URL } from "../../../config";
import { saveGraph } from "../../../helpers/importExportFunctions";

const isTypeCsv = (file: UploadFile) => {
    return (
        file.type === "text/csv" ||
        file.type === "text/plain" ||
        file.type === "application/csv" ||
        file.type === "application/vnd.ms-excel" ||
        file.type === "text/x-csv"
    );
};

const delayFunction = <F extends (...args: any[]) => void>(
    fn: F,
    ms: number
): ((...args: Parameters<F>) => void) => {
    let timer: NodeJS.Timeout | null = null;

    return function (...args: Parameters<F>) {
        clearTimeout(timer as NodeJS.Timeout);
        timer = setTimeout(() => {
            fn(...(args as Parameters<F>));
        }, ms);
    };
};

const delayUrlLog = delayFunction(
    (type: string, context: any) => debouncedAutoSave(type, context),
    1000
);

type Dataset = {
    dataset_name: string;
    dataset_path: string;
};

const COMPONENT_ACTION_ID = "importDataset";

export const DatasetImporterComponent = (props: { data: DatasetImporterTextControls }) => {
    const [text, setText] = useState<string>(props.data.value);
    const [switchStatus, setSwitchStatus] = useState<boolean>(!props.data.useUploadedDataset);
    const [file, setFile] = useState<File | null>(null);
    const [fileUploading, setFileUploading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<boolean>(false);
    const [savedFile, setSavedFile] = useState<UploadFile[] | null>(null);
    const containerRef = useRef<HTMLDivElement>(null);

    const context = props.data.context;

    const toggleUrlImportStep = (action: string) => {
        if (props.data.isValidCsvUrl(action)) {
            context.markTaskAsCompleted(COMPONENT_ACTION_ID);
        } else {
            context.removeTaskFromCompleted(COMPONENT_ACTION_ID);
        }
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        props.data.onChange(e.target.value);
        setText(e.target.value);
        toggleUrlImportStep(e.target.value);
        delayUrlLog("enter_url_download", {
            url_download: true,
            currentURL: e.target.value,
        });
    };

    const handleFileRead = (file: RcFile, skipLog = false): void => {
        setFileUploading(true);
        const reader = new FileReader();
        reader.onload = async (e) => {
            const content = e.target?.result;
            if (typeof content === "string") {
                setFile(file);
                props.data.uploadDataset(content, file.name);
            }
        };
        reader.readAsText(file);
        if (!skipLog) {
            debouncedAutoSave("upload_local_dataset", { fileName: file.name, url_download: false });
        }
    };

    const beforeUpload = async (file: RcFile, fileList: UploadFile[], skipUpload = false) => {
        if (!isTypeCsv(file)) {
            fileList[0].status = "error";
            return false;
        }
        if (!skipUpload) {
            await uploadFileToServer(file);
        }
        handleFileRead(file, skipUpload);
        if (context) {
            const { markTaskAsCompleted } = context;
            markTaskAsCompleted(COMPONENT_ACTION_ID);
        }
        return false;
    };

    const onRemove = (file: UploadFile) => {
        setFile(null);
        setSavedFile(null);
        setErrorMessage(false);
        props.data.setDatasetColumns([]);
        context.removeTaskFromCompleted(COMPONENT_ACTION_ID);
        debouncedAutoSave("remove_local_dataset", { fileName: file.name, url_download: false });
        props.data.uploadDataset("", null);
        return true;
    };

    const getElementTotalHeight = (el: HTMLElement) => {
        const styles = window.getComputedStyle(el);
        const margin = parseFloat(styles["marginTop"]) + parseFloat(styles["marginBottom"]);
        return el.offsetHeight + margin;
    };

    const sumImmediateChildrenHeights = (parentDiv: HTMLElement) => {
        let totalHeight = 0;
        for (let child of parentDiv.children) {
            totalHeight += getElementTotalHeight(child as HTMLElement);
        }
        return totalHeight;
    };

    const getCurrentHeight = () => {
        if (!containerRef.current) return 295;

        const controlContainer: HTMLElement = containerRef.current;
        const overallNodeContainer: HTMLElement =
            controlContainer.closest("[data-testid='node']") || document.createElement("div")!;
        const totalRequiredHeight = sumImmediateChildrenHeights(overallNodeContainer);
        // Clear any hardcoded dimensions from the auto arrange plugin
        overallNodeContainer.style.height = "";
        overallNodeContainer.style.width = "";
        return totalRequiredHeight + 5;
    };

    const onSwitch = (checked: boolean) => {
        props.data.setDatasetSource(!checked);
        setSwitchStatus(checked);
        setErrorMessage(false);
        if (checked) {
            toggleUrlImportStep(text);
        } else if (!file) {
            context.removeTaskFromCompleted(COMPONENT_ACTION_ID);
        }
        debouncedAutoSave("toggle_url_download_switch", {
            url_download: checked,
            current_url: text,
            current_file: file?.name || "No file uploaded",
        });
    };

    const uploadFileToServer = async (file: File) => {
        const formData = new FormData();
        try {
            formData.append("file", file);
            const res = await axiosInstance.post("/api/file/upload", formData);
            setErrorMessage(false);
        } catch (error: any) {
            console.log(error);
            if (error.response.status === 413) {
                console.log("File too large to save");
                setErrorMessage(true);
                return true;
            }
        }
        return false;
    };

    const getUploadedDatasetPath = async (fileName: string) => {
        try {
            const res = await axiosInstance.get(`/api/file/get-path/${fileName}`);
            return res.data.dataset;
        } catch (error: any) {
            console.log(error);
            return null;
        }
    };

    const setupForPreviouslyUploadedDataset = async () => {
        const dataset: Dataset | null = await getUploadedDatasetPath(
            props.data.uploadedDataset.fileName
        );
        if (dataset) {
            try {
                const res = await axiosInstance.get(`${BACKEND_URL}${dataset.dataset_path}`, {
                    responseType: "blob",
                });
                const blob = res.data;
                const file = new File([blob], dataset.dataset_name, {
                    type: blob.type,
                });
                const tempUploadFile: UploadFile = {
                    uid: "-1",
                    name: dataset.dataset_name,
                    status: "done",
                    // url: `${BACKEND_URL}${dataset.dataset_path}`,
                };

                setSavedFile([tempUploadFile]);
                await beforeUpload(file as RcFile, [file as RcFile], true);
            } catch (error: any) {
                console.log("Failed to retrieve dataset path, resorting to default behavior");
            }
        } else {
            console.log("Failed to retrieve dataset path, resorting to default behavior");
        }
    };

    useEffect(() => {
        if (containerRef.current) {
            const overalDatasetImporter: HTMLElement =
                containerRef.current.closest("[data-testid='node']") ||
                document.createElement("div");
            // Reset hardcoded dimensions from auto arrange plugin
            overalDatasetImporter.style.width = "";
            overalDatasetImporter.style.height = "";
        }
    }, [file]);

    useEffect(() => {
        if (props.data.useUploadedDataset && props.data.uploadedDataset.fileName !== "") {
            setupForPreviouslyUploadedDataset();
        }
    }, []);

    useEffect(() => {
        const observer = new ResizeObserver((entries) => {
            for (let entry of entries) {
                if (entry.target === containerRef.current) {
                    const height = getCurrentHeight();
                    props.data.adjustHeight(height);
                }
            }
        });

        if (containerRef.current) {
            observer.observe(containerRef.current);
        }

        // Cleanup observer on unmount
        return () => {
            observer.disconnect();
        };
    }, []);

    return (
        <ConfigProvider
            theme={{
                algorithm: theme.darkAlgorithm,
            }}
        >
            <Flex
                gap={"small"}
                align="center"
                vertical={true}
                ref={containerRef}
                onPointerDown={(e) => e.stopPropagation()}
            >
                <Upload
                    accept=".csv"
                    listType="text"
                    maxCount={1}
                    beforeUpload={beforeUpload}
                    onRemove={onRemove}
                    disabled={switchStatus}
                    fileList={savedFile || undefined}
                    className="fileUploadContainer"
                >
                    <Button disabled={switchStatus} icon={<UploadOutlined />}>
                        Upload dataset (csv)
                    </Button>
                </Upload>
                {errorMessage && (
                    <span className="fileUploadErrorMessage">
                        File too large to save, only available until refresh
                    </span>
                )}
                <span>OR</span>
                <Flex gap={"small"}>
                    <span>URL download?</span>
                    <Switch checked={switchStatus} onChange={onSwitch} disabled={fileUploading} />
                </Flex>
                <Input
                    disabled={!switchStatus}
                    placeholder="Enter dataset url"
                    value={text}
                    onChange={handleChange}
                    aria-label="Enter dataset URL"
                    name="datasetUrlInput"
                />
                <DatasetViewerComponent
                    file={file}
                    useUrl={switchStatus}
                    url={text}
                    setDatasetColumns={props.data.setDatasetColumns}
                    fileUploading={fileUploading}
                    setFileUploading={setFileUploading}
                    isValidCsvUrl={props.data.isValidCsvUrl}
                />
            </Flex>
        </ConfigProvider>
    );
};
