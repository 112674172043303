import { ClassicPreset } from "rete";
import {
    DataOutput,
    DataframeConditions,
    PreviousNodeData,
} from "../ReteInterfaces/SharedInterfaces";
import { getPreviousNodes } from "../../helpers/nodeHelpers";
import { DatasetImporter } from "./DatasetImporter";
import { PackageImporter } from "./PackageImporter";
import { DataframeLoaderControl } from "../ReteControls/DataframeLoaderControls";
import { ProblemContextType } from "../../contexts/ProblemContext";

interface UploadedDataset {
    content: string;
    fileName: string;
}

export class DataframeLoader extends ClassicPreset.Node<
    {
        input: ClassicPreset.Socket;
    },
    { output: ClassicPreset.Socket },
    { status_indicator: DataframeLoaderControl }
> {
    width = 260;
    height = 200;
    initialMessages = ["Connect dataset importer", "Connect library importer"];
    task_id = "createDataframeLoader";
    private change: () => void;
    private update: (
        type: "node" | "socket",
        node: DataframeLoader | ClassicPreset.Input<ClassicPreset.Socket>
    ) => void;
    constructor(
        socket: ClassicPreset.Socket,
        update: (
            type: "node" | "socket",
            asset: DataframeLoader | ClassicPreset.Input<ClassicPreset.Socket>
        ) => void,
        change: () => void,
        context: ProblemContextType
    ) {
        super("Load Dataframe");
        this.addInput("input", new ClassicPreset.Input(socket, "Input", true));
        this.addOutput("output", new ClassicPreset.Output(socket, "Export Dataframe"));
        this.addControl(
            "status_indicator",
            new DataframeLoaderControl("initial", this.initialMessages, context)
        );
        this.update = update;
        this.change = change;
    }
    getType() {
        return "DataframeLoader";
    }
    getDisplayName() {
        return "Dataframe Loader";
    }
    #isValidCsvUrl(url: string) {
        const urlPattern = new RegExp(
            "^(https?:\\/\\/)?" +
                "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
                "((\\d{1,3}\\.){3}\\d{1,3}))" +
                "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
                "(\\?[;&a-z\\d%_.~+=-]*)?" +
                "(\\#[-a-z\\d_]*)?$",
            "i"
        );

        if (!urlPattern.test(url)) {
            return false;
        }
        if (!url.toLowerCase().endsWith(".csv")) {
            return false;
        }
        return true;
    }

    #requiredConditions(prevNodes: PreviousNodeData[]): DataframeConditions {
        const datasetNode = prevNodes.find(
            (node) => node.label.toLowerCase() === "import dataset"
        ) as DatasetImporter | undefined;
        const packageImporterNode = prevNodes.find(
            (node) => node.label.toLowerCase() === "import libraries"
        ) as PackageImporter | undefined;
        const useUploadedDataset = datasetNode?.useUploadedDataset || null;
        const uploadedDataset = datasetNode?.uploadedDataset || null;
        const url_value = datasetNode?.value || "";
        const isDatasetNodeConnected = !!datasetNode;
        const isUploadedDatasetEmpty = uploadedDataset?.content === "";
        const isUrlEmpty = url_value === "";
        const isDatasetLoaded = isDatasetNodeConnected
            ? datasetNode.datasetColumns.length > 0
            : false;
        const isPackageImporterNodeConnected = !!packageImporterNode;
        const isPandasImported =
            packageImporterNode?.selectedItems.some(
                (item: string) => item.toLowerCase() === "pandas"
            ) || false;
        return {
            useUploadedDataset,
            uploadedDataset,
            url_value,
            isDatasetNodeConnected,
            isUploadedDatasetEmpty,
            isUrlEmpty,
            isPackageImporterNodeConnected,
            isPandasImported,
            isDatasetLoaded,
        };
    }

    #generateCurrentCode(prevNodes: PreviousNodeData[]): string {
        const requiredConditions = this.#requiredConditions(prevNodes);
        const {
            useUploadedDataset,
            uploadedDataset,
            url_value,
            isDatasetNodeConnected,
            isPackageImporterNodeConnected,
            isPandasImported,
            isUploadedDatasetEmpty,
            isUrlEmpty,
            isDatasetLoaded,
        } = requiredConditions;

        let currentCode = "";

        this.controls.status_indicator.clearMessages();
        if (!isDatasetNodeConnected) {
            currentCode += "# Connect a dataset importer node\n";
            this.controls.status_indicator.addMessage("Connect dataset importer");
        } else {
            if (useUploadedDataset) {
                if (isUploadedDatasetEmpty) {
                    currentCode += "# Select a dataset for upload\n";
                    this.controls.status_indicator.addMessage("Upload a dataset");
                } else {
                    currentCode += `df = pd.read_csv("${uploadedDataset?.fileName}")\n`;
                }
            } else {
                if (isUrlEmpty) {
                    currentCode += "# Enter a url to import dataset\n";
                    this.controls.status_indicator.addMessage("Enter a url to import dataset");
                } else {
                    if (this.#isValidCsvUrl(url_value) && isDatasetLoaded) {
                        currentCode += `df = pd.read_csv("${url_value}")\n`;
                    } else {
                        currentCode += "# Invalid url used for import\n";
                        this.controls.status_indicator.addMessage("Invalid dataset url");
                    }
                }
            }
        }
        if (!isPackageImporterNodeConnected) {
            currentCode = ["# Connect a library importer node\n", currentCode].join("");
            this.controls.status_indicator.addMessage("Connect library importer");
        }
        if (!isPandasImported && isPackageImporterNodeConnected) {
            currentCode = ["# Import pandas library to create Dataframe\n", currentCode].join("");
            this.controls.status_indicator.addMessage("Import pandas");
        }

        return currentCode;
    }

    #mergeInputCode(inputs: DataOutput[]): string[] {
        const mergedInput = inputs.map((input) => input.code);
        return mergedInput.flat();
    }

    data(inputs: { input?: DataOutput[] }): {
        output: DataOutput;
    } {
        this.controls.status_indicator.setLoadingStatus("loading");

        const mergedInput = inputs.input || [];
        const previousCode = this.#mergeInputCode(mergedInput);

        let previousNodes: PreviousNodeData[] = [];
        if (mergedInput) {
            previousNodes = getPreviousNodes(mergedInput);
        }

        let currentCode: string = this.#generateCurrentCode(previousNodes);

        const code: string[] = [...previousCode, currentCode];
        const currentNode = { ...this, connectedPort: this.outputs.output! };
        const output = {
            code,
            previousNodes: [...previousNodes, currentNode],
        };

        if (this.controls.status_indicator.messages.length === 0) {
            this.controls.status_indicator.setLoadingStatus("success");
        } else {
            this.controls.status_indicator.setLoadingStatus("error");
        }
        this.update("node", this);

        return {
            output,
        };
    }
}
